import React from "react";
import theme from "../config/theme.json";
import ButtonBar from "./ButtonBar";
import LayoutBox from "./LayoutBox";
import { useState } from "react";
import { sendEmail } from "../api/email";

const AboutTab = () => {
  const [sent, setSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const tagColor = theme.colorC;
  return (
    <>
      <p>
        I am a <span style={{ color: theme.colorB }}>Software Engineer</span>{" "}
        with 9 years of professional development experience, with particular
        experience building full stack web and mobile applications. I've built
        software targeting various platforms, using various languages,
        technologies, and frameworks, with particular proficiency in{" "}
        <span style={{ color: tagColor }}>React</span> and{" "}
        <span style={{ color: tagColor }}>Python (Django/Flask)</span>.
      </p>
      <p>
        Other proficiencies include:
        <br />
        <span style={{ color: tagColor }}>TypeScript</span>,{" "}
        <span style={{ color: tagColor }}>RESTful Web Services</span>,{" "}
        <span style={{ color: tagColor }}>Docker</span>,{" "}
        <span style={{ color: tagColor }}>Unit Testing</span>,{" "}
        <span style={{ color: tagColor }}>React Native</span>,{" "}
        <span style={{ color: tagColor }}>Git</span>,{" "}
        <span style={{ color: tagColor }}>MySQL</span>,{" "}
        <span style={{ color: tagColor }}>Postgres</span>,{" "}
        <span style={{ color: tagColor }}>Redis</span>,{" "}
        <span style={{ color: tagColor }}>Agile / Scrum</span>,{" "}
        <span style={{ color: tagColor }}>AWS</span>,{" "}
        <span style={{ color: tagColor }}>PHP</span>,{" "}
        <span style={{ color: tagColor }}>Java</span>
      </p>
      <ButtonBar
        buttons={[
          { label: "View Resume", url: "/jrv-resume.pdf" },
          { label: "LinkedIn", url: "https://www.linkedin.com/in/jerryvoltz/" },
        ]}
      />
      <LayoutBox title={sent ? "" : "Contact"}>
        {sent ? (
          <span className="success">Message Sent</span>
        ) : (
          <>
            <input
              type="text"
              placeholder="Name"
              id="name"
              value={name}
              style={{
                borderColor: theme.borderColor,
                backgroundColor: theme.lighterBackgroundColor,
                color: theme.textColor,
              }}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company"
              id="company"
              value={company}
              style={{
                borderColor: theme.borderColor,
                backgroundColor: theme.lighterBackgroundColor,
                color: theme.textColor,
              }}
              onChange={(e) => setCompany(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email Address"
              id="email"
              style={{
                borderColor: theme.borderColor,
                backgroundColor: theme.lighterBackgroundColor,
                color: theme.textColor,
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              placeholder="Message"
              id="message"
              style={{
                borderColor: theme.borderColor,
                backgroundColor: theme.lighterBackgroundColor,
                color: theme.textColor,
              }}
              defaultValue={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {error && <span className="error">{error}</span>}
            <ButtonBar
              buttons={[
                {
                  label: "Send Message",
                  onClick: () => {
                    if ((name || email) && company && message) {
                      sendEmail(name, email, company, message);
                      setSent(true);
                    } else {
                      setError("All fields are required.");
                    }
                  },
                },
              ]}
            />
          </>
        )}
      </LayoutBox>
    </>
  );
};

export default AboutTab;
